<template>
  <el-dialog
    :visible.sync="visible"
    :show-close="false"
    width="90%"
    custom-class="modal-container"
  >
    <div slot="title" class="title">
      <span class="info">
        {{ title }}
      </span>

      <van-icon
        class="icon"
        name="cross"
        color="#666666"
        @click="visible = false"
      />
    </div>
    <div class="pay-select">
      <div
        v-for="item in goodsList"
        :key="item.id"
        class="pay-select-item"
        :class="{
          active: activeGoods.id === item.id,
          disabled: item.status === 0
        }"
        @click="changeActive(item)"
      >
        <div class="pay-select-item-inner">
          <div class="left">
            <div class="pay-title">{{ item.title }}</div>
            <div class="price">{{ parseFloat(item.price) }}</div>
          </div>
          <div class="right">
            <div
              v-for="(rightItem, index) in item.rights"
              :key="rightItem"
              class="desc"
            >
              {{ index + 1 }}、{{ rightItem }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="activeGoods.id" class="pay-info">
      <div class="pay-info-inner">
        <van-button class="pay-btn" block color="#CA0A15" @click="confirmPay"
          >立即订购</van-button
        >
      </div>
    </div>
  </el-dialog>
</template>

<script>
import { mapState } from "vuex";
import { Dialog } from "element-ui";
import { Icon, Button } from "vant";
import apis from "@/apis/index";
import requestPay from "@/utils/requestPay";
import isweixin from "@/utils/isweixin";

export default {
  name: "PayDialog",
  components: {
    "van-icon": Icon,
    "el-dialog": Dialog,
    "van-button": Button
  },
  props: {
    bookId: {
      type: Number,
      default: NaN
    },
    bookPrice: {
      type: String,
      default: "0"
    }
  },
  data() {
    return {
      visible: false,
      activeGoods: {},
      currApi: apis.recharge
    };
  },
  computed: {
    ...mapState(["userInfo", "vipIndex"]),
    title() {
      return this.bookId ? "观看族谱" : "购买会员";
    },
    vipGoods() {
      return this.vipIndex.map(item => ({
        type: "vip",
        id: item.id,
        title: item.levelname,
        price: item.award,
        status: item.status,
        rights: [
          "有效期一年",
          `可查看收费谱书${item.viewcount}本`,
          `上传谱书${item.uploadcount}本`,
          `上传谱书分润${parseFloat(item.uploadaward)}%`,
          `直推会员收益${parseFloat(item.recommendone)}%`,
          !!parseFloat(item.recommendoneSec) &&
            `直推会员续费收益${parseFloat(item.recommendoneSec)}%`,
          !!parseFloat(item.recommendtwo) &&
            `间推会员收益${parseFloat(item.recommendtwo)}%`,
          !!parseFloat(item.recommendtwoSec) &&
            `间推会员续费收益${parseFloat(item.recommendtwoSec)}%`
        ].filter(Boolean)
      }));
    },
    currGoods() {
      return {
        type: "goods",
        id: `book_${this.bookId}`,
        title: "单独购买",
        price: this.bookPrice,
        status: 1,
        rights: ["有效期一年"]
      };
    },
    goodsList() {
      return this.bookId ? [this.currGoods, ...this.vipGoods] : this.vipGoods;
    }
  },
  mounted() {
    // this.fetchVipIndex();
    this.activeGoods = {};
  },
  methods: {
    // ...mapActions(["fetchVipIndex"]),
    show() {
      this.visible = true;
      this.activeGoods = {};
    },

    hide() {
      this.visible = false;
      this.qrcode = "";
      this.orderNo = "";
    },

    changeActive(item) {
      if (item.status === 0) return;
      this.activeGoods = item;
      if (item.type === "vip") {
        this.currApi = apis.recharge;
      } else {
        this.currApi = apis.rechargeBook;
      }
      this.orderNo = "";
      // this.confirmPay();
    },

    async confirmPay() {
      this.$toast.loading();
      const goodsId =
        this.activeGoods.type === "goods"
          ? this.activeGoods.id.split("_")[1]
          : this.activeGoods.id;
      if (isweixin()) {
        const { data } = await this.currApi({
          goods_id: goodsId,
          trade_type: "JSAPI",
          ukey: this.userInfo.openid
        });
        this.$toast.clear();
        requestPay(data)
          .then(() => {
            this.$toast("支付成功");
            this.$emit("success");
            this.visible = false;
          })
          .catch(err => {
            this.$toast(err.err_msg);
          });
        console.log("pay", data);
      } else {
        // const {
        //   data: { mweb_url }
        // } = await this.currApi({
        //   goods_id: goodsId,
        //   trade_type: "MWEB"
        // });
        // if (mweb_url) {
        //   location.href = mweb_url;
        //   this.$dialog
        //     .confirm({
        //       title: "提示",
        //       message: "是否支付成功？"
        //     })
        //     .then(() => {
        //       this.$emit("success");
        //     });
        // }
        this.$toast("请到微信内打开页面支付");
        // console.log(data);
      }
    },

    async submit() {
      this.visible = false;
      this.$emit("submit", this.searchForm);
    }
  },
  watch: {
    visible(val) {
      if (!val) {
        this.orderNo = "";
      }
    }
  }
};
</script>

<style lang="scss">
.form-input {
  .el-input__inner {
    color: #CA0A15;
    font-size: 14px;
    border: 1px solid #f0f0f0;
  }
}
</style>

<style lang="scss">
.modal-container {
  .el-dialog__header {
    padding: 0;
  }
  .el-dialog__body {
    padding: 0;
  }
}
</style>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.title {
  align-items: center;
  background: #f7f7f7;
  border-radius: 10px 10px 0px 0px;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  height: 120px;
  justify-content: space-between;
  overflow: hidden;
  padding-left: 40px;
  padding-right: 40px;
  position: relative;
  width: 100%;

  .info {
    color: #333;
    font-size: 48px;
    letter-spacing: 0;
    line-height: 48px;
    text-decoration: none;
    font-weight: bold;
  }
}

.pay-select {
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-evenly;
  align-items: center;
  padding: 40px;

  &-item {
    width: 100%;
    height: 350px;
    border-radius: 10px;
    background: rgba(140, 65, 52, 0.3);
    box-sizing: border-box;
    border: 4px solid transparent;
    margin-bottom: 40px;
    padding: 20px;

    .pay-select-item-inner {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      border: 1px dashed #b5b5b5;
      border-radius: 10px;
      padding: 30px 0;
      box-sizing: border-box;
      --themeColor: #666666;
    }

    &.active {
      border: 4px solid #8c4134;

      .pay-select-item-inner {
        border: 1px dashed #8c4134;
        --themeColor: #8c4134;
      }
    }

    &.disabled {
      opacity: 0.7;
      cursor: not-allowed;
    }

    .left {
      width: 280px;
      height: 100%;
      display: flex;
      flex-flow: column nowrap;
      justify-content: center;
      align-items: center;
      .pay-title {
        color: var(--themeColor);
        font-size: 30px;
        text-align: center;
        font-weight: bold;
        margin-top: 24px;
        line-height: 1;
      }

      .price {
        color: var(--themeColor);
        font-size: 100px;
        text-align: center;
        font-weight: bold;
        margin-top: 19px;
        line-height: 1;

        &::before {
          content: "¥";
          font-size: 96px;
          color: var(--themeColor);
        }
      }
    }

    .right {
      flex: 1;
      height: 100%;
      border-left: 1px solid var(--themeColor);
      display: flex;
      justify-content: flex-start;
      align-items: center;
      flex-flow: row wrap;
      // padding-right: 10px;
      padding-left: 20px;
      box-sizing: border-box;
    }

    .desc {
      font-size: 24px;
      color: var(--themeColor);
      margin-left: 0px;
      line-height: 1.2;
      width: 48%;
      // height: 2em;
      margin-top: 1em;
      // flex: none;
      word-break: break-all;

      &:nth-of-type(1) {
        margin-top: 0;
      }

      &:nth-of-type(2) {
        margin-top: 0;
      }
      // white-space: nowrap;
    }
  }
}

.pay-info {
  box-sizing: border-box;
  width: 100%;
  padding: 20px;
  padding-top: 0;

  .pay-info-inner {
    width: 100%;
    height: 180px;
    // background-color: #f7f7f7;
    border-radius: 4px;
    display: flex;
    align-items: center;
    padding: 0 20px;
    box-sizing: border-box;

    .pay-btn {
      // height: 120px;
      border-radius: 10px;
    }
  }
}
</style>
