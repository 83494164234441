function onBridgeReady(params, resolve, reject) {
  window.WeixinJSBridge.invoke(
    "getBrandWCPayRequest",
    {
      ...params
    },
    function(res) {
      if (res.err_msg == "get_brand_wcpay_request:ok") {
        // 使用以上方式判断前端返回,微信团队郑重提示：
        resolve(res);
        //res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
      } else {
        reject(res);
      }
    }
  );
}

export default function(params) {
  return new Promise((resolve, reject) => {
    if (typeof WeixinJSBridge == "undefined") {
      if (document.addEventListener) {
        document.addEventListener(
          "WeixinJSBridgeReady",
          () => {
            onBridgeReady(params, resolve, reject);
          },
          false
        );
      } else if (document.attachEvent) {
        document.attachEvent("WeixinJSBridgeReady", () => {
          onBridgeReady(params, resolve, reject);
        });
        document.attachEvent("onWeixinJSBridgeReady", () => {
          onBridgeReady(params, resolve, reject);
        });
      }
    } else {
      onBridgeReady(params, resolve, reject);
    }
  });
}
